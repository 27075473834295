<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    height="90%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择老师</span>
    </div>
    <!-- multipleSelectionTotal：{{multipleSelectionTotal}}
    <hr>
    seleAssisData：{{seleAssisData}} -->
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom:20px">
          <el-row :gutter="20" v-if="activeName === 'allList'">
            <el-col :span="16">
              <el-input
                placeholder="请输入内容"
                v-model="inputVal"
                class="input-with-select"
                @keyup.enter.native="search"
              >
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
                  <el-option label="用户名" value="1"></el-option>
                  <el-option label="姓名" value="2"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="本班老师" name="waitList">
              <el-table
                :data="waitList"
                ref="waitList"
                border
                fit
                highlight-current-row
                :row-key="getRowKeys"
                @selection-change="waithandleselectChange"
              >
                <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
                <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
                <el-table-column label="姓名" align="center" prop="name"></el-table-column>
              </el-table>
            </el-tab-pane>
            <!-- 全部老师 -->
            <el-tab-pane label="全部老师" name="allList">
              <el-table
                :data="allList"
                ref="allList"
                border
                fit
                highlight-current-row
                :row-key="getRowKeys"
                @selection-change="allhandleselectChange"
              >
                <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
                <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
                <el-table-column label="姓名" align="center" prop="name"></el-table-column>
              </el-table>
              <el-pagination
                class="pagination"
                @current-change="allhandleCurrentChange"
                :current-page="allPagination.currentPage"
                :page-sizes="allPagination.pageSizes"
                :page-size="allPagination.pageSize"
                :layout="allPagination.layout"
                :total="allPagination.total"
              ></el-pagination>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;">
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
          </el-row>
          <ul v-for="(item, index) of multipleSelectionTotal" :key="index" class="seleList">
            <li class="sele-item">
              {{item.name}} [{{item.loginName}}]
              <i
                class="el-icon-delete-solid ico-del"
                @click="delStu(index, item.userId)"
              ></i>
            </li>
          </ul>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListTeacher } from '@/api/senate/class'
import { getUserList } from '@/api/system/staff'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return null
      },
      type: Object
    },
    seleAssisData: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  watch: {
    multipleSelectionWait (newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.clearWaitMerge(newVal, oldVal)
      }
    },
    multipleSelectionAll (newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.clearAllMerge(newVal, oldVal)
      }
    }
  },
  data () {
    return {
      inputVal: '',
      select: '',
      activeName: 'waitList',
      waitList: [], // 待分班学员列表
      allList: [], // 全部学员
      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: 'total, prev, pager, next, jumper',
        total: 0
      },
      multipleSelectionTotal: [], // 已选择学员
      multipleSelectionWait: [], // 待分班学员
      multipleSelectionAll: [], // 全部学员
      idKey: 'userId'
    }
  },
  methods: {
    openDialog () {
      this.allPagination.currentPage = 1
      this.activeName = 'waitList'
      this.multipleSelectionWait = []
      this.multipleSelectionAll = []
      this.multipleSelectionTotal = this.seleAssisData
      // this.chooseLoadList()
      this.getListTeacher()
      this.getUserList()
    },

    handleClick () {
      this.mergeCheck()
      // this.chooseLoadList()
      // console.log('multipleSelectionTotal', this.multipleSelectionTotal)
    },

    chooseLoadList () {
      switch (this.activeName) {
        case 'waitList':
          this.getListTeacher()
          break
        case 'allList':
          this.getUserList()
          break
      }
    },

    search () {
      this.allPagination.currentPage = 1
      this.chooseLoadList()
    },

    getRowKeys (row) {
      return row.userId
    },

    // 获取本班老师列表
    async getListTeacher () {
      const res = await getListTeacher({
        classId: this.$route.query.classID ? this.$route.query.classID : this.classInfo.id
      })
      res.body.forEach((ele, idx) => {
        ele.userId = ele.id
      })
      this.waitList = res.body
      this.mergeCheck()
    },

    // 全部老师列表
    async getUserList () {
      const res = await getUserList({
        pageNum: this.allPagination.currentPage,
        pageSize: this.allPagination.pageSize,
        state: 1,
        roleCodeSet: [2],
        loginName: this.select === '1' ? this.inputVal : '',
        name: this.select === '2' ? this.inputVal : ''
      })
      this.allList = res.body.list
      this.allPagination.total = res.body.total
      this.mergeCheck()
    },

    mergeCheck () {
      if (this.activeName === 'waitList') {
        this.waitList.forEach((row, index) => {
          this.multipleSelectionTotal.forEach((ele, idx) => {
            if (row.userId === ele.userId) {
              this.$refs.waitList.toggleRowSelection(this.waitList[index], true)
            }
          })
        })
      } else {
        this.allList.forEach((row, index) => {
          this.multipleSelectionTotal.forEach((ele, idx) => {
            if (row.userId === ele.userId) {
              this.$refs.allList.toggleRowSelection(this.allList[index], true)
            }
          })
        })
      }
    },

    // 清除勾选
    clearWaitMerge (newVal, oldVal) {
      let ids = []
      oldVal.forEach((ele) => {
        ids.push(ele.userId)
      })
      let idKey = this.idKey
      for (let i = 0; i < this.allList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.allList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.allList.toggleRowSelection(this.allList[i], false)
          }
        }
      }
    },

    clearAllMerge (newVal, oldVal) {
      let ids = []
      oldVal.forEach((ele) => {
        ids.push(ele.userId)
      })
      let idKey = this.idKey
      for (let i = 0; i < this.waitList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.waitList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
          }
        }
      }
    },

    waithandleselectChange (val) {
      this.multipleSelectionWait = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionAll.concat(val).concat(this.seleAssisData))
    },

    allhandleselectChange (val) {
      this.multipleSelectionAll = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionWait.concat(val).concat(this.seleAssisData))
    },

    // 关闭
    close () {
      this.$emit('close')
      this.$refs.waitList.clearSelection()
      this.$refs.allList.clearSelection()
    },

    // 分页
    allhandleCurrentChange (val) {
      this.allPagination.currentPage = val
      this.getUserList()
    },

    // 保存
    confirm () {
      this.$nextTick(() => {
        this.$emit('assistantData', this.multipleSelectionTotal)
        this.close()
      })
    },

    getNewList (arr, userId) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.userId) && res.set(a.userId, 1))
    },

    // 删除学员
    delStu (index, id) {
      if (!this.multipleSelectionTotal || this.multipleSelectionTotal.length <= 0) {
        return
      }
      this.multipleSelectionTotal.splice(index, 1)
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let selectAllIds = id
      // if (this.activeName === 'waitList') {
      for (let i = 0; i < this.seleAssisData.length; i++) {
        if (selectAllIds.indexOf(this.seleAssisData[i][idKey]) >= 0) {
          this.seleAssisData.splice(i, 1)
        }
      }
      for (let i = 0; i < this.waitList.length; i++) {
        if (selectAllIds.indexOf(this.waitList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
        }
      }
      for (let i = 0; i < this.allList.length; i++) {
        if (selectAllIds.indexOf(this.allList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.allList.toggleRowSelection(this.allList[i], false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
</style>
