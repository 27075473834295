var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        "append-to-body": "",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("检测到冲突")])]
      ),
      _c("h3", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v("冲突的排课"),
      ]),
      _vm._l(_vm.clashData, function (item, index) {
        return _c(
          "ul",
          { key: index, staticClass: "detection-con" },
          [
            item.conflictType instanceof Array
              ? _c("li", [
                  _c("span", { staticStyle: { color: "#EB9316" } }, [
                    _vm._v("教室冲突"),
                  ]),
                  _c("span", [_vm._v("&&")]),
                  _c("span", { staticStyle: { color: "#CE433F" } }, [
                    _vm._v("老师冲突"),
                  ]),
                ])
              : _c("li", [
                  _vm._v("冲突原因："),
                  _c("span", {
                    style: _vm.getTypeColor(item.conflictType),
                    domProps: {
                      textContent: _vm._s(_vm.clashText(item.conflictType)),
                    },
                  }),
                ]),
            _c("li", [_vm._v("班级名称：" + _vm._s(item.className))]),
            _c("li", [
              _vm._v(
                "排课时间：" +
                  _vm._s(_vm._f("formatDateStart")(item.startTime)) +
                  " " +
                  _vm._s(_vm._f("formatDateEnd")(item.endTime))
              ),
            ]),
            String(item.conflictType).indexOf("1") !== -1
              ? [
                  item.teacherType === "主教"
                    ? _c("li", [
                        _vm._v("任课老师：" + _vm._s(item.teacherName)),
                      ])
                    : _c("li", [
                        _vm._v("助教老师：" + _vm._s(item.teacherName)),
                      ]),
                ]
              : _vm._e(),
            String(item.conflictType).indexOf("2") !== -1
              ? [_c("li", [_vm._v("冲突教室：" + _vm._s(item.classroomTitle))])]
              : _vm._e(),
          ],
          2
        )
      }),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }