<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <!-- clashData：{{clashData}} -->
    <div slot="title" class="dia-tit">
      <i></i>
      <span>检测到冲突</span>
    </div>
    <h3 style="margin-bottom: 10px;">冲突的排课</h3>
    <ul class="detection-con" v-for="(item, index) of clashData" :key="index">
      <li v-if="item.conflictType instanceof Array">
        <span style="color: #EB9316">教室冲突</span>
        <span>&&</span>
        <span style="color: #CE433F">老师冲突</span>
      </li>
      <li v-else>冲突原因：<span v-text="clashText(item.conflictType)" :style="getTypeColor(item.conflictType)"></span></li>
      <li>班级名称：{{item.className}}</li>
      <li>排课时间：{{item.startTime | formatDateStart}} {{item.endTime | formatDateEnd}}</li>
      <template v-if="String(item.conflictType).indexOf('1') !== -1">
        <li v-if="item.teacherType === '主教'">任课老师：{{item.teacherName}}</li>
        <li v-else>助教老师：{{item.teacherName}}</li>
      </template>
      <template v-if="String(item.conflictType).indexOf('2') !== -1">
        <li>冲突教室：{{item.classroomTitle}}</li>
      </template>
    </ul>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="close">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    clashData: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data () {
    return {
      formData: {}
    }
  },
  methods: {
    openDialog () {},
    // 关闭
    close () {
      this.$emit('close')
    },

    clashText (state) {
      switch (state) {
        case "1":
          return '老师冲突'
        case "2":
          return '教室冲突'
        default:
          return '未知冲突'
      }
    },

    getTypeColor (state) {
      switch (state) {
        case "1":
          return "color:#EB9316;"
        case "2":
          return "color:#CE433F;"
        default:
          return "color:#4DA54D;"
      }
    },
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.detection-con {
  border: 1px solid #aaaaaa;
  padding: 15px;
  margin-bottom: 15px;
  li {
    margin-bottom: 5px;
  }
}
</style>
