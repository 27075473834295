<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="75%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>老师空闲时间</span>
    </div>
    <!-- tableData: {{tableData}}
    weekDayArr：{{weekDayArr}}
    chooseDate：{{chooseDate}} -->
    <el-row>
      <!-- <el-select v-model="schoolValue" placeholder="请选择老师" class="tb-smselect">
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select> -->
      <el-select
        v-model="teacherIds"
        multiple
        placeholder="请选择上课老师"
        @click.native="seleTeacher"
        class="tb-smselect"
      >
        <el-option
          v-for="item in teacherArray"
          :key="item.userId"
          :label="item.name"
          :value="item.userId"
        ></el-option>
      </el-select>
      <el-date-picker
        class="tb-picker"
        v-model="chooseDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择排课日期">
      </el-date-picker>
      <!-- <el-date-picker
        class="tb-picker"
        v-model="chooseDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        value-format="yyyy-MM-dd"
        end-placeholder="结束日期">
      </el-date-picker> -->
      <el-select v-model="type" placeholder="请选择讲师类型" class="tb-smselect">
        <el-option v-for="item in typeArray" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="small" @click="reset" class="tb-button">重置</el-button>
      <el-button type="primary" size="small" @click="lastclick" class="tb-button" :disabled="!currentFirstDate">上周</el-button>
      <el-button type="primary" size="small" @click="nextclick" class="tb-button" :disabled="!currentFirstDate">下周</el-button>
      （助教为橙色、主教为绿色）
      <!-- table -->
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        :row-class-name="tableRowClassName"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="teacherName" label="教师" align="center"></el-table-column>
        <el-table-column prop="timeSlice" label="时间段" align="center" width="80"></el-table-column>
        <el-table-column v-for="(item, index) in weekDayArr" :key="index" :label="item.adate" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.quesData.length !== 0">
              <p v-for="(ele, idx) of scope.row.quesData" :key="idx">
                <span v-if="ele.startTime.substring(0, 10) === item.adate.substring(0, 10)">
                  <span :style="'color' + ':' + getStateColor(ele.teacherType)">
                    {{ele.className}} {{ele.startTime | formatDateEnd}}~{{ele.endTime | formatDateEnd}}
                  </span>
                </span>
              </p>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!-- 选择老师 -->
    <sele-assistantIds :show="assistantIdsDialog" @close="assistantIdsDialog=false" @assistantData="assistantData" :seleAssisData="seleAssisData"></sele-assistantIds>

  </el-dialog>
</template>

<script>
import { getListLessonTeacher } from '@/api/senate/class'
import { formatTate } from '@/utils/formatDate'
import seleAssistantIds from '@/components/senate/sele-newassistantIds'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    pageFrom: {
      default: "",
      type: String
    }
  },
  components: { seleAssistantIds },
  data () {
    return {
      teacherIds: [],
      chooseDate: null,
      type: '',
      teacherArray: [],
      tableData: [],
      schoolArray: [],
      typeArray: [
        {
          value: null,
          label: '全部'
        },
        {
          value: '主教',
          label: '主教'
        },
        {
          value: '助教',
          label: '助教'
        }
      ],
      currentFirstDate: '',
      clen: 7,
      // todayDate: '',
      weekDayArr: [],
      minTime: '',
      maxTime: '',
      selectTeacherDialog:false,
      assistantIdsDialog: false,
      seleAssisData: []
    }
  },
  methods: {
    openDialog () {
      this.tableData = []
      this.teacherIds = []
      this.weekDayArr = []
      this.chooseDate = null
      this.seleAssisData = []
      console.log(this.pageFrom)
      // this.todayDate = this.formatDate(new Date())
    },

    close () {
      this.$emit('close')
    },

    search () {
      if (!this.chooseDate) {
        window.$msg('请选择排课时间段', 2)
        return false
      } else if (this.teacherIds.length === 0) {
        window.$msg('请选择老师', 2)
        return false
      } else {
        this.weekDayArr = []
        // let now = new Date()
        let now = new Date(this.chooseDate)
        let nowTime = now.getTime()
        let day = now.getDay()
        let oneDayLong = 24 * 60 * 60 * 1000
        let MondayTime = nowTime - (day - 1) * oneDayLong
        let SundayTime = nowTime + (7 - day) * oneDayLong
        this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
        this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
        this.setDate(new Date(this.chooseDate))
        this.getListLessonTeacher()
      }
    },

    assistantData (data) {
      this.teacherIds = [] // 置空选项
      this.teacherArray = data
      data.forEach((ele, idx) => {
        this.teacherIds.push(ele.userId)
      })
    },

    teacherData(data) {
      this.teacherIds = [] // 置空选项
      this.teacherArray = data
      data.forEach((ele, idx) => {
        this.teacherIds.push(ele.userId)
      })
    },

    reset () {
      this.teacherIds = []
      this.type = ''
      this.chooseDate = null
      this.currentFirstDate = ''
    },

    // 选择讲师
    seleTeacher () {
      this.teacherArray.forEach((ele, idx) => {
        this.teacherIds.forEach((item, index) => {
          if (ele.userId === item) {
            this.seleAssisData.push(ele)
          }
        })
      })
      if(this.pageFrom === "") {
        this.assistantIdsDialog = true
      }else {
        this.selectTeacherDialog = true
      }
    },

    async getListLessonTeacher () {
      const res = await getListLessonTeacher({
        // teacherIdSet: ['d2686ae0-cc82-4f8e-be72-52414ec1aabd', 'd3510930-0a77-479f-8ff4-03c425aca762'],
        teacherIdSet: this.teacherIds,
        teacherType: this.type,
        minTime: this.minTime,
        maxTime: this.maxTime
      })
      this.tableData = res.body.list
      this.regroupData(this.tableData)
    },

    getNewList (arr, teacherId) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.teacherId) && res.set(a.teacherId, 1))
    },

    // 数据重组
    regroupData (data) {
      let qwData = []
      let teacherIds = []
      data.forEach((ele, idx) => {
        teacherIds.push({
          teacherId: ele.teacherId,
          teacherName: ele.teacherName
        })
      })
      teacherIds = this.getNewList(teacherIds) // 本次查询的老师
      // console.log('teacherIds', teacherIds)
      teacherIds.forEach((ele, idx) => {
        qwData.push({
          timeSlice: '上午',
          quesData: [],
          teacherId: ele.teacherId,
          teacherName: ele.teacherName
        })
        qwData.push({
          timeSlice: '下午',
          quesData: [],
          teacherId: ele.teacherId,
          teacherName: ele.teacherName
        })
        qwData.push({
          timeSlice: '晚上',
          quesData: [],
          teacherId: ele.teacherId,
          teacherName: ele.teacherName
        })
      })

      // console.log('表格挂载数据', qwData)
      data.forEach((ele, idx) => {
        qwData.forEach((item, index) => {
          if (item.teacherId === ele.teacherId && 0 <= Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 12 && item.timeSlice === '上午') {
            item.quesData.push({ ...ele })
          } else if (item.teacherId === ele.teacherId && 12 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 18 && item.timeSlice === '下午') {
            item.quesData.push({ ...ele })
          } else if (item.teacherId === ele.teacherId && Number(ele.startTime.substring(10, 13)) > 18 && item.timeSlice === '晚上') {
            item.quesData.push({ ...ele })
          }
        })
      })
      // console.log('qwData', qwData)
      // qwData.forEach((item, index) => {
      //   data.forEach((ele, idx) => {
      //     if (0 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 12 && item.timeSlice === '上午') {
      //       item.quesData.push({ ...ele })
      //     } else if (12 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 18 && item.timeSlice === '下午') {
      //       item.quesData.push({ ...ele })
      //     } else if (Number(ele.startTime.substring(10, 13)) > 18 && item.timeSlice === '晚上') {
      //       item.quesData.push({ ...ele })
      //     }
      //   })
      // })
      this.tableData = qwData
    },

    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    tableRowClassName ({ row, rowIndex, column }) {
      if (rowIndex % 3 === 0) {
        return 'info-row'
      } else if (rowIndex % 3 === 1) {
        return 'warning-row'
      } else if (rowIndex % 3 === 2) {
        return 'success-row'
      } else {
        return 'info-row'
      }
    },
    // 日期格式处理
    formatDate (date) {
      var year = date.getFullYear() + '-'
      var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) + '-' : '0' + (date.getMonth() + 1) + '-'
      var day = (date.getDate() > 9) ? date.getDate() + '' : '0' + date.getDate() + ''
      var week =
        '(' +
        ['周天', '周一', '周二', '周三', '周四', '周五', '周六'] [
          date.getDay()
        ] +
        ')'
      return year + month + day + ' ' + week
    },
    //
    addDate (date, n) {
      date.setDate(date.getDate() + n)
      return date
    },
    //
    setDate (date) {
      var week = date.getDay() - 1
      date = this.addDate(date, week * -1)
      this.currentFirstDate = new Date(date)
      for (var i = 0; i < this.clen; i++) {
        this.weekDayArr.push({
          adate: this.formatDate(i === 0 ? date : this.addDate(date, 1))
        })
        // 只有日期
        // this.weekDayArr.push(this.formatDate(i==0 ? date : this.addDate(date,1)))
      }
    },
    // 上一周
    lastclick () {
      this.weekDayArr = []
      this.tableData = []
      let nextWeek = this.addDate(new Date(this.currentFirstDate), -7)
      let nowTime = nextWeek.getTime()
      let day = nextWeek.getDay()
      let oneDayLong = 24 * 60 * 60 * 1000
      let MondayTime = nowTime - (day - 1) * oneDayLong
      let SundayTime = nowTime + (7 - day) * oneDayLong
      this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
      this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
      this.setDate(this.addDate(new Date(this.currentFirstDate), -7))
      this.getListLessonTeacher()
    },
    // 下一周
    nextclick () {
      this.weekDayArr = []
      this.tableData = []
      let nextWeek = this.addDate(new Date(this.currentFirstDate), 7)
      let nowTime = nextWeek.getTime()
      let day = nextWeek.getDay()
      let oneDayLong = 24 * 60 * 60 * 1000
      let MondayTime = nowTime - (day - 1) * oneDayLong
      let SundayTime = nowTime + (7 - day) * oneDayLong
      this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
      this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
      this.setDate(this.addDate(new Date(this.currentFirstDate), 7))
      this.getListLessonTeacher()
    },

    getStateColor (state) {
      switch (state) {
        case '主教' :
          return '#4DA54D'
        case '助教' :
          return '#EB9316'
        default :
          return '#4DA54D'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

/deep/ .el-table .info-row {
  background: #E0E0F8;
}
/deep/ .el-table .warning-row {
  background: #B2C5FF;
}

/deep/ .el-table .success-row {
  background: #7787C5;
}
</style>
